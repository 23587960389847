/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:4ece9f89-1f98-48d9-9312-ca3652f9b5df",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_n8YTTVLww",
    "aws_user_pools_web_client_id": "5mi12lshmghuhuqfk2i9c0gke0",
    "oauth": {
        "domain": "medmap.auth.eu-west-2.amazoncognito.com",
        "scope": ["email", "openid", "profile"],
        "redirectSignIn": "http://localhost:3001/,https://www.northstarapp.co.uk/",
        "redirectSignOut": "http://localhost:3001/,https://www.northstarapp.co.uk/",
        "responseType": "code"
    },
    "aws_cognito_username_attributes": ["EMAIL"],
    "aws_cognito_social_providers": ["Google"],
    "aws_cognito_signup_attributes": ["EMAIL"],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": ["SMS"],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": ["EMAIL"]
};

export default awsmobile;